const App = () => {
  return (
      <iframe style={
        {
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0, 
          right: 0,
          width: '100%',
          height: '100%',
          border: 'none',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          zIndex: 999999
         }
      }
       src="https://doc.clickup.com/36622063/d/h/12xkqf-15487/13aeb18a54f4159" title='clickup'></iframe>
  );
}

export default App;